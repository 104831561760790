.landing-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.placeholders_container {
  display: flex;
  height: 40vh;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}
.placeholders_container > img {
  height: 100%;
  max-width: 50%;
  object-fit: scale-down;
}

.placeholder {
  animation-duration: 2s;  
}

.phgolee {
  height: 15vh;
  animation-name: slideInFromLeft;
}

.phfigc {
  height: 20vh;
  animation-name: slideInFromRight;
  width: 40%;
  object-fit: scale-down;
}

@keyframes slideInFromLeft {
  from {transform: translateX(-300px);}
  to {transform: translateX(0);}
}
@keyframes slideInFromRight {
  from {transform: translateX(300px);}
  to {transform: translateX(0);}
}

.second-section-container {
  padding-top: 5%;
  display: flex;
  flex-direction: row;
  animation-duration: 1s;  
  animation-delay: 1s;
  animation-name: fadein;
  opacity: 0;
  animation-fill-mode: forwards;
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

.info-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.tutorial-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title {
  display: flex;
  flex: 0.1;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 1em;
}

.second-section-content {
  display: flex;
  flex: 0.9;
  width: 80%;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;;
}

.contacts{
  margin-bottom: 1em;
}
.contacts div.ant-typography{
  margin-bottom:0;
}