@import '~antd/dist/antd.css';

.App {
  text-align: center;;
}

.App-logo {
  height: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding: 12px;
  transition: all .1s ease-in-out;
}
.App-logo:hover {
  transform: scale(1.1);
}

.App-header {
  background: #fff; 
  padding: 0;
  display: flex;
  flex: 1;
  max-height: 64px;
  justify-content: space-between;
  align-items: center ;
  padding: 0 48px 0 48px;
}

.AppContent-container {
  display: flex;
  flex: 1;
}

.App-content {
  margin: 24px 16px 0 16px;
  padding: 24px;
  background: #fff;
  flex: 1;
}

.App-footer {
  text-align: center;
}

.App-link {
  color: #61dafb;
}

.trigger {
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.logo {
  height: 15vh;
  display: flex;
  flex: 0 1 100%;
  justify-content: space-around;
  padding: 10px 0;
}
.logo > img {
  height: 100%;
}